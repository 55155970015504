@import url("~@chhjit/react-components/dist/assets/styles/main.css");

html {
  overflow: initial !important;
  height: 100% !important;
  min-height: 100% !important;
}

body {
  overflow: initial !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100% !important;
  min-height: 100% !important;
  -webkit-text-size-adjust: 100%;
}

#root {
  overflow: initial !important;
  height: 100% !important;
  min-height: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  -webkit-tap-highlight-color: transparent;
}

// MUI IconButton
.iconButtonSmall {
  margin: -4px !important;
}

.iconButtonMedium {
  margin: -12px !important;
}

// pointer-events
.pointerEventsNone {
  pointer-events: none;
}

// cursor
.cursorPointer {
  cursor: pointer;
}

// flex
.flex-1 {
  flex: 1;
}

// text-decoration
.textDecorationNone {
  text-decoration: none;
}
